import React from "react";
import { useNavigate } from "react-router-dom";
import "./NotFound.scss";
const InvalidConsultation = () => {
  const navigate = useNavigate();
  const handleClickButton = () => {
    navigate("/my-consultations");
  };
  return (
    <div className="not__found__container">
      <div>
        <span>Oops</span>
        <h1>Invalid Consultation.....</h1>
        <p>
          Sorry, the page that you are looking for doesn't exist or has been
          moved.
        </p>
        <button onClick={handleClickButton}>Go Back</button>
      </div>
      <img
        src="https://static.meragoapp.com/images/invalidDetails.png"
        alt="Merago"
        onError={(event) => (event.target.style.display = "none")}
      />
    </div>
  );
};

export default InvalidConsultation;
