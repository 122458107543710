import { Link } from "react-router-dom";
var CryptoJS = require("crypto-js");
const ManageClinic = ({ providerData, clinicData }) => {
  //! handle Manage CLinic Click
  const handleClinincSSO = (clinicId) => {
    //! generate key
    let shaStringKey = CryptoJS.HmacSHA1(
      `${localStorage.getItem("providerId")}${clinicId}${providerData?.email}`,
      "BhiveWorkSpace"
    ).toString();

    window.open(
      `${process.env.REACT_APP_E_DERMA}?pid=${localStorage.getItem(
        "providerId"
      )}&cid=${clinicId}&email=${
        providerData?.email
      }&access_token=${localStorage.getItem("auth")}&key=${shaStringKey}`
    );
    console.log(
      "url",
      `${process.env.REACT_APP_E_DERMA}?pid=${localStorage.getItem(
        "providerId"
      )}&cid=${clinicId}&email=${
        providerData?.email
      }&access_token=${localStorage.getItem("auth")}&key=${shaStringKey}`
    );
  };

  return (
    <>
      {localStorage.getItem("clinicId") !== undefined ||
      localStorage.getItem("clinicId") !== null ? (
        <Link
          to=""
          onClick={() =>
            handleClinincSSO(
              localStorage.getItem("clinicId")
                ? localStorage.getItem("clinicId")
                : clinicData?.clinicProvider[0]?.clinicId
            )
          }
        >
          Manage Clinic
        </Link>
      ) : (
        <></>
      )}
    </>
  );
};

export default ManageClinic;
