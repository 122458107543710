export const navigationItems = {
  mainMenu: [
    {
      name: "Home",
      to: "/dashboard",
      text: "dashboard",
    },
    {
      name: "My Consultations",
      to: "/my-consultations",
      text: "consultations",
    },
    {
      name: "Skincare Case Review",
      to: "/skincare-saf",
      text: "skincare",
    },
    {
      name: "Dermacare",
      to: "/dermacare",
      text: "dermacare",
    },
    {
      name: "Calander",
      to: "/calander",
      text: "calander",
    },
    {
      name: "Billing & Payments ",
      to: "/billing",
      text: "billing",
    },
    {
      name: "Patients ",
      to: "/patients",
      text: "patients",
    },
  ],
  loginMenu: [
    {
      name: "Sign In ",
      to: "/login",
      text: "login",
    },
    {
      name: "Sign Up",
      to: "/register",
      text: "register",
    },
  ],
  agentLoginMenu: [
    {
      name: "Sign In ",
      to: "/agent",
      text: "agent",
    },
  ],

  sideBar: [
    {
      name: "Bank Details",
      to: "/bank-details",
      text: "bank",
    },
    {
      name: "Change Password",
      to: "/change-password",
      text: "password",
    },
    {
      name: "Personal Information",
      to: "/personal-info",
      text: "personal",
    },
    {
      name: "Professional Information",
      to: "/professional-info",
      text: "professional",
    },
    {
      name: "Roster",
      to: "/roster",
      text: "roster",
    },
    {
      name: "Service Information",
      to: "/service-info",
      text: "service",
    },
    {
      name: "Vacation/Block Calander",
      to: "/vacation",
      text: "vacation",
    },
    {
      name: "Inbox",
      to: "/inbox",
      text: "inbox",
    },
  ],
  footer: [],
};
export default navigationItems;
