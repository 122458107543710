import axios from "axios";
const geo = axios.create({
  baseURL: "https://maps.googleapis.com",
  headers: {
    "Content-Type": "application/json",
    "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
    "X-Frame-Options": "SAMEORIGIN",
    "Content-Security-Policy": "default-src 'self'",
    "X-Content-Type-Options": "nosniff",
    "Referrer-Policy": "strict-origin-when-cross-origin",
    "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
    "Allow-Origin": process.env.REACT_APP_DOMAIN,
  },
});
geo.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const geoRequest = {
  get: (url, params) =>
    geo
      .get(url, { params })
      .then((res) => res.data)
      .catch((err) => Promise.reject(err.response.data)),
};
//! 1
export const getProperLocation = (params) =>
  geoRequest.get("/maps/api/geocode/json", params);
