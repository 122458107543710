import axios from "axios";

const byodLogin = axios.create({
  baseURL: process.env.REACT_APP_LOGINURL,
  headers: {
    Username: "ClientId",
    Password: "Secret",
    "Content-Type": "application/json",
    "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
    "X-Frame-Options": "SAMEORIGIN",
    "Content-Security-Policy": "default-src 'self'",
    "X-Content-Type-Options": "nosniff",
    "Referrer-Policy": "strict-origin-when-cross-origin",
  },
});
byodLogin.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
      Authorization: "Basic Q2xpZW50SWQ6U2VjcmV0",
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const request = {
  get: (url, params) =>
    byodLogin
      .get(url, { params })
      .then((res) => res.data)
      .catch((err) => Promise.reject(err.response.data)),
};

//* 1.Get Provider Uploaded Docs By Id
export const byodLoginCall = (params) => request.get("login", params);
