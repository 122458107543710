import error from "../assets/images/error.png";
import downArrow from "../assets/images/down.svg";
import close from "../assets/images/close.svg";

const assets = {
  error,
  downArrow,
  close,
};
export default assets;
