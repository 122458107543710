import { useMutation, useQuery } from "react-query";
import * as login from "../services/loginAPI";
import * as geo from "../services/geoCodeApi";
import * as loginOTP from "../services/loginOtpAPI";
const _ = require("lodash");
export const useForgotPassword = () => {
  return useMutation((params) => login.forgotPassword(params), {
    retry: false,
  });
};
export const useVerifyOTP = () => {
  return useMutation((params) => login.verifyOTP(params), {
    retry: false,
  });
};
export const useResetPassword = () => {
  return useMutation((params) => login.resetPassword(params), {
    retry: false,
  });
};
export const useGeoLocations = (params) => {
  return useQuery(["locations", params], () => geo.getProperLocation(params), {
    enabled: !!params?.address,
  });
};
export const useSendLoginOTP = () => {
  return useMutation((params) => login.sendLoginOTP(params), {
    retry: false,
  });
};
export const useVerifyLoginOTP = () => {
  return useMutation((params) => login.doctorVerifyOTP(params), {
    retry: false,
  });
};

//* Login With OTP
export const useLoginWithOTP = (params) => {
  return useQuery(["Login-OTP", params], () => loginOTP.loginWIthOTP(params), {
    enabled: !_.isEmpty(params?.username),
    retry: false,
  });
};
