import { isMobile } from "react-device-detect";

export const mainCustomModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "720px",
    height: "600px",
    overflowY: "hidden",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "3",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
  },
};
export const resubmitModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "720px",
    height: "350px",
    overflowY: "hidden",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "3",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
  },
};
export const healthProfileModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "380px",
    height: "750px",
    overflowY: "hidden",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "3",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
  },
};
export const consentModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "1100px",
    height: "700px",
    overflowY: "scroll",
    padding: "0 !important",
    border: "3px solid rgb(106 174 5)",
    zIndex: "3",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
  },
};
export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "720px",
    height: "500px",
    overflowY: "hidden",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "3",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
  },
};
export const errorModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "420px",
    height: "200px",
    overflowY: "hidden",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "3",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
  },
};
export const caseNotesModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "820px",
    height: "660px",
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "999",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
    zIndex: "9999 !important",
  },
};
export const associateModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "540px",
    minHeight: "360px",
    height: "auto",
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "999",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
    zIndex: "9999 !important",
  },
};
export const caseNotesModalSOStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "820px",
    // height: "660px",
    maxHeight: "660px",
    height: "auto",
    overflowY: "hidden",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "999",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
    zIndex: "9999 !important",
  },
};
export const secondOpinionCaseStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(10%, -50%)",
    width: "620px",

    maxHeight: "580px",
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid rgb(34 64 114)",
    zIndex: "999",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
    zIndex: "9999 !important",
  },
};

export const secondOpinionCaseNotesStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-90%, -50%)",
    width: "720px",
    height: "auto",
    maxHeight: "580px",
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "999",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
    zIndex: "9999 !important",
  },
};

export const consentModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "920px",
    height: "660px",
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "999",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
    zIndex: "9999 !important",
  },
};
export const previousSessionModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "820px",
    height: "500px",
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "999",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
    zIndex: "9999 !important",
  },
};

export const caseNotesMobileStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: "80vh",
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "999",
    overflowX: "hidden",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
    zIndex: "9999 !important",
  },
};
export const chatMobileStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: "510px",
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "999",
    overflowX: "hidden",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
    zIndex: "9999 !important",
  },
};

export const messagesModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "620px",
    height: "450px",
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "3",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
  },
};

export const smallModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: !isMobile ? "420px" : "320px",
    height: "200px",
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "199990 !important",
    position: "relative",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
    zIndex: "999 !important",
  },
};
export const mhConfirmModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "420px",
    height: "280px",
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "199990 !important",
    position: "relative",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
    zIndex: "999 !important",
  },
};
export const cookieAlertModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "420px",
    height: "330px",
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "199990 !important",
    position: "relative",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
    zIndex: "999 !important",
  },
};

export const smallModalStylesMobile = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: "40vh",
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "999",
    overflowX: "hidden",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
    zIndex: "9999 !important",
  },
};
export const calendarModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "330px",
    height: "230px",
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "1050 !important",
    position: "relative",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
    zIndex: "9999 !important",
  },
};
export const agentAlertModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "280px",
    height: "240px",
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "1050 !important",
    position: "relative",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
    zIndex: "9999 !important",
  },
};
export const alertNewModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "280px",
    height: "340px",
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "1050 !important",
    position: "relative",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
    zIndex: "9999 !important",
  },
};

export const abdmConsentFormRequest = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "750px",
    height: "550px",
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid #008060",
    zIndex: "1050 !important",
    position: "relative",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
    zIndex: "9999 !important",
  },
};

export const orderIdModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "440px",
    height: "300px",
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid #66c0ff",
    zIndex: "1050 !important",
    position: "relative",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
    zIndex: "9999 !important",
  },
};

export const feedBackModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "350px",
    height: "550px",
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "3",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
  },
};
export const consultationMessagesStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    height: "510px",
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "3",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
    zIndex: "4",
  },
};
export const cartModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "460px",
    height: "650px",
    overflowY: "hidden",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "3",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
    zIndex: "4",
  },
};

export const forwardMessageStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: !isMobile ? "600px" : "360px",
    height: "600px",
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "3",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
    zIndex: "4",
  },
};
export const messageToExpertByAdmin = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    height: "400px",
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "3",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
    zIndex: "4",
  },
};

export const rescheduleModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "700px",
    height: "625px",
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "3",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
  },
};
export const nextAppointmentModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: !isMobile ? "700px" : "340px",
    maxHeight: "425px",
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "3",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
  },
};

export const longModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "360px",
    maxHeight: "625px",
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "3",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
  },
};

export const fileUploadModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    height: "355px",
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "3",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
  },
};

export const calendarModalAdd = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "560px",
    height: "500px",
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "1050 !important",
    position: "relative",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
    zIndex: "9999 !important",
  },
};

export const calendarModalAddMobile = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "350px",
    height: "500px",
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "9999 !important",
    position: "relative",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
    zIndex: "9999 !important",
  },
};

export const calendarShare = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "580px",
    height: "560px",
    overflow: "hidden",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "1050 !important",
    position: "relative",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
    zIndex: "9999 !important",
  },
};
export const calendarShareMobile = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "350px",
    height: "500px",
    overflow: "hidden",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "1050 !important",
    position: "relative",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
    zIndex: "9999 !important",
  },
};
export const customModalStyle = (width, height) => ({
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: width,
    height: height,
    overflowY: "auto",
    padding: "0 !important",
    border: "3px solid rgb(101 192 254)",
    zIndex: "9999 !important",
    position: "relative",
  },
  overlay: {
    background: "rgba(4, 4, 4, 0.83)",
    zIndex: "9999 !important",
  },
});
