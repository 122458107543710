import { Link } from "react-router-dom";
const SidebarMenu = ({
  providerData,
  clinicData,
  setOpen,
  open,
  profileCompletionData,
}) => {
  return (
    <>
      {providerData?.providerVerificationStatus === "VERIFIED_AND_APPROVED" && (
        <>
          <Link to="/bank-details" onClick={() => setOpen(!open)}>
            Bank Details
          </Link>
          <Link to="/change-password" onClick={() => setOpen(!open)}>
            Change Password
          </Link>
        </>
      )}
      {profileCompletionData?.personalInfo === true && (
        <Link to="/personal-info" onClick={() => setOpen(!open)}>
          Personal Information
        </Link>
      )}
      {profileCompletionData?.professionalInfo === true && (
        <Link to="/professional-info" onClick={() => setOpen(!open)}>
          Professional Information
        </Link>
      )}
      {profileCompletionData?.serviceInfo === true && (
        <Link to="/service-info" onClick={() => setOpen(!open)}>
          Service Information
        </Link>
      )}
      {providerData?.providerVerificationStatus === "VERIFIED_AND_APPROVED" && (
        <>
          <Link to="/roster" onClick={() => setOpen(!open)}>
            Roster
          </Link>

          <Link to="/vacation" onClick={() => setOpen(!open)}>
            Vacation/Block Calander
          </Link>
          {clinicData?.clinicProvider?.length > 1 && (
            <Link to="/clinics" onClick={() => setOpen(!open)}>
              Switch Clinic
            </Link>
          )}
        </>
      )}
      <Link to="/inbox" onClick={() => setOpen(!open)}>
        Inbox
      </Link>
    </>
  );
};

export default SidebarMenu;
