import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useGetAgentByUserId } from "../../../hooks/useAgent";
import { useLogout } from "../../../hooks/useLogout";
import assets from "../../../utils/Assets";
import AgentLoginMenu from "./AgentLoginMenu";
import withOutsideClickAgent from "./withOutsideClickAgent";
import { useCODData } from "../../../context/CODDataContext";

const AgentMenu = React.forwardRef(({ setOpen, open }, ref) => {
  const { refetch, data: logoutData } = useLogout();
  const useAuth = () => {
    const user = localStorage.getItem("agentID");
    return Boolean(user);
  };
  const user = useAuth();
  const location = useLocation();
  const navigation = useNavigate();
  const { isAlertAgentWaiting } = useCODData();
  const { data: agentData } = useGetAgentByUserId(
    localStorage.getItem("agentID") ? localStorage.getItem("agentID") : ""
  );

console.log("isAlertAgentWaiting",isAlertAgentWaiting)
  const logout = () => {
    refetch();
    if (logoutData?.status === "SUCCESS") {
      localStorage.removeItem("agentAu");
    }
    localStorage.removeItem("agentID");
    localStorage.removeItem("agentAu");
    localStorage.removeItem("expires_in");
    localStorage.removeItem("isSCAdmin");
    localStorage.removeItem("refresh_token");
    navigation("/agent");
    setOpen(!open);
  };

  useEffect(() => {
    if (agentData?.status === "SUCCESS") {
      localStorage.setItem("agentFname", agentData?.agentDetails?.firstname);
      localStorage.setItem("agentLname", agentData?.agentDetails?.lastname);
    }
  }, [agentData]);

  const showAlert = isAlertAgentWaiting;
  return (
    <>
      {user && (
        <div className="navbar" ref={ref}>
          <div className="navbar__left">
            <div className="navbar__logo">
              <img
                src="https://static.meragoapp.com/images/menuLogo.png"
                alt="logo"
                width="55px"
                height="55px"
              />
            </div>

            <div className="navbar__items nav__more__left">
              {/* {agentData?.agentDetails?.agentType !== "AGENT_PT" && (
                <Link
                  to="agent-alert"
                  className={
                    location.pathname.includes("/agent-alert")
                      ? "sidebar_active"
                      : ""
                  }
                >
                  AlertU Services
                </Link>
              )} */}
              {agentData?.agentDetails?.agentType !== "AGENT_PT" && (
                <Link
                  to="agent-alert"
                  className={
                    location.pathname.includes("/agent-alert")
                      ? "sidebar_active"
                      : ""
                  }
                >
                  {isAlertAgentWaiting && (
                    <div className={showAlert ? "red__alert" : ""}></div>
                  )}
                  AlertU Services
                </Link>
              )}
              <Link
                to="/agent-dashboard"
                className={
                  location.pathname.includes("/agent-dashboard")
                    ? "sidebar_active"
                    : ""
                }
              >
                {agentData?.agentDetails?.agentType !== "AGENT_PT"
                  ? "Lab Services"
                  : "Home Care"}
              </Link>
              {agentData?.agentDetails?.agentType !== "AGENT_PT" && (
                <Link
                  to="agent-soboard"
                  className={
                    location.pathname.includes("/agent-soboard")
                      ? "sidebar_active"
                      : ""
                  }
                >
                  Second Opinion Services
                </Link>
              )}
              {agentData?.agentDetails?.agentType !== "AGENT_PT" && (
                <Link
                  to="agent-diagnostic"
                  className={
                    location.pathname.includes("/agent-diagnostic")
                      ? "sidebar_active"
                      : ""
                  }
                >
                  Diagnostic Services
                </Link>
              )}
              {agentData?.agentDetails?.agentType !== "AGENT_PT" && (
                <Link
                  to="agent-redcliff"
                  className={
                    location.pathname.includes("/agent-redcliff")
                      ? "sidebar_active"
                      : ""
                  }
                >
                  Red Cliff Services
                </Link>
              )}
            </div>
          </div>
          <div className="sideBar__main" onClick={() => setOpen(!open)}>
            <p>
              {agentData?.agentDetails?.firstname}{" "}
              {agentData?.agentDetails?.lastname}
            </p>
            <img src={assets.downArrow} alt="merago" />
          </div>
          {open ? (
            <div className="sidebar__conatiner agent__sidebar">
              {location.pathname !== "/agent" && (
                <button onClick={logout}>Logout</button>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      <AgentLoginMenu />
    </>
  );
});

export default withOutsideClickAgent(AgentMenu);
