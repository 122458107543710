import { useMutation, useQuery, useQueryClient } from "react-query";
import * as nurse from "../services/nurseAPI";
const _ = require("lodash");

//? AlertU List
export const useNurseAlertWithFilter = () => {
  return useMutation((data) => nurse.getAgentAlertWithFilterNurse(data), {
    retry: false,
  });
};
//* Get Shared Records
export const useGetSharedRecordsNurse = (params) => {
  return useQuery(
    ["Shared-Records", params],
    () => nurse.getSharedRecordsNurse({ consultationId: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
      refetchInterval: 8000,
    }
  );
};
//* Get COD Details
export const useGetCODDetailsNurse = (params) => {
  return useQuery(
    ["COD-Details", params],
    () => nurse.getCODDetails({ urgenrtCareReqId: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
      refetchInterval: 5000,
    }
  );
};
//* Get Profile Values
export const useGetProfileValuesNurse = (params) => {
  return useQuery(
    ["Profile-Values", params],
    () => nurse.getProfileValues(params),
    {
      enabled: !!params?.memberId,
      retry: false,
    }
  );
}; //* Get Profile DP
export const useGetProfileDPNurse = (params) => {
  return useQuery(["Profile-DP", params], () => nurse.getProfileDP(params), {
    enabled: !!params?.userId,
    retry: false,
  });
};

//? Update Start Service Status
export const useNurseJoinConsultation = () => {
  return useMutation((data) => nurse.nurseJoinConsultation(data), {
    retry: false,
  });
};

//* Get Consultations by consultation number
export const useGetConsultationsNurse = (params) => {
  return useQuery(
    ["Consultations", params],
    () => nurse.getConsultations({ consultationNumber: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
      refetchInterval: 5000,
    }
  );
};
//? Update Nurse Leave
export const useUpdateUrgentCareNurseComments = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => nurse.updateUrgentCareNurseComments(data), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries("Consultations");
    },
  });
};

//* Get Agent Details
export const useGetNurseByUserId = (params) => {
  return useQuery(
    ["Agent-Details", params],
    () => nurse.getAgentByUserId({ userId: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
    }
  );
};

//* Get Consultation Messages
export const useGetConsultationMsgsNurse = (params) => {
  return useQuery(
    ["Consultation-Messages", params],
    () => nurse.getConsultationMessages({ consultationId: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
      refetchInterval: 2000,
    }
  );
};

//?Send Consultation Message
export const useSendConsultationMessageNurse = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => nurse.sendConsultationMessage(data), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries("Consultation-Messages");
    },
  });
};

//? Nurse List - Urgent care list
export const useUrgentCareNurseRequests = () => {
  return useMutation((data) => nurse.urgentCareNurseRequests(data), {
    retry: false,
  });
};

//? Nurse List
export const useTalkToNurseRequests = () => {
  return useMutation((data) => nurse.talkToNurseRequests(data), {
    retry: false,
  });
};

export const useGetDocsByIdNurse = () => {
  return useMutation((data) => nurse.getDocsById(data), {
    retry: false,
  });
};
//?Get Case Notes on click
export const useCaseNotesURLV2Nurse = () => {
  return useMutation((data) => nurse.caseNotesURLV2(data), {
    retry: false,
  });
};
//* Get Nurse Upload Docs List
export const useGetNurseDocs = (params) => {
  return useQuery(
    ["Nurse-UploadDocs", params],
    () => nurse.getNurseUploadDocs(params),
    {
      enabled: !_.isEmpty(params),
      retry: false,
    }
  );
};

//? Upload Provider Doc
export const useUploadNurseDoc = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => nurse.uploadNurseDoc(data), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries("Nurse-UploadDocs");
    },
  });
};

//? Update Document Capture Screen
export const useUpdateDocumentCaptureStatus = () => {
  return useMutation((data) => nurse.updateDocumentCaptureStatus(data), {
    retry: false,
  });
};
