import { createContext, useContext, useState, useEffect } from "react";
import { useGetProviderDetails } from "../hooks/useProvider";

const ProviderDataContext = createContext();

export const useProviderDataContext = () => {
  return useContext(ProviderDataContext);
};

export const ProviderDataProvider = ({ children }) => {
  const [providerData, setProviderData] = useState(null);
  const params = localStorage.getItem("userID") || "";

  const { data } = useGetProviderDetails(params);

  useEffect(() => {
    if (data) {
      setProviderData(data);
    }
  }, [data]);

  return (
    <ProviderDataContext.Provider value={{ providerData, setProviderData }}>
      {children}
    </ProviderDataContext.Provider>
  );
};
