import { useEffect } from 'react';

const BreakoutIframe = () => {
  useEffect(() => {
    if (window !== window.top) {
      window.top.location.href = window.location.href;
    }
  }, []);

  return null;
};

export default BreakoutIframe;
