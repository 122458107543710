import axios from "axios";
const loginOTP = axios.create({
  baseURL: process.env.REACT_APP_LOGINURL,
  headers: {
    Username: "ClientId",
    Password: "Secret",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    Authorization: "Basic Q2xpZW50SWQ6U2VjcmV0",
  },
});

loginOTP.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const request = {
  get: (url, params) =>
    loginOTP
      .get(url, { params })
      .then((res) => res.data)
      .catch((err) => Promise.reject(err.response.data)),
};
//1->API
export const loginWIthOTP = (params) => request.get("/login", params);
