// nurseAPIClient.js
import axios from "axios";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { getUnixTime } from "date-fns";

// Create a common axios instance
const nurseAPIClient = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    "Content-Type": "application/json",
    "Referrer-Policy": "strict-origin-when-cross-origin",
    "Access-Control-Allow-Origin": process.env.REACT_APP_BASEURL,
    "Allow-Origin": process.env.REACT_APP_BASEURL,
    Accept: "application/json",
  },
});

let isRefreshing = false; //! Flag to indicate refresh status
let pendingRequests = []; //! Queue to hold pending requests

//* Function to get current time in seconds
const getCurrentTimeInSeconds = () => getUnixTime(new Date());

//! Function to decode the JWT and get the expiration time
const getExpirationTime = (token) => {
  if (!token) return null;
  const decodedToken = jwtDecode(token);
  return decodedToken.exp; // exp is in seconds
};

//! Function to retrieve the auth token
const getAuthToken = () => {
  const auth = localStorage.getItem("nurseAuth");
  return auth ? `Bearer ${auth}` : null;
};

//! Function to refresh the auth token
const refreshAuthToken = async () => {
  const refreshToken = localStorage.getItem("nurse_refresh_token");
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_LOGINURL}/getRefreshToken`,
      null, // empty body
      {
        params: { refreshToken: refreshToken },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic Q2xpZW50SWQ6U2VjcmV0",
          Username: "ClientId",
          Password: "Secret",
        },
      }
    );

    const { access_token, expires_in } = response.data;
    localStorage.setItem("nurseAuth", access_token);

    // Set new expiration time
    const expirationTime = getCurrentTimeInSeconds() + expires_in;
    localStorage.setItem("expires_in", expirationTime);

    // Resolve all pending requests with the new token
    pendingRequests.forEach((callback) => callback(access_token));
    pendingRequests = []; // Clear the queue after processing

    return access_token;
  } catch (error) {
    toast.error("Session expired. Please log in again. . ");
    // localStorage.clear();
    window.location = "/login";
    throw error; // To stop the request that triggered this
  }
};
//! Axios request interceptor to handle token refresh
nurseAPIClient.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("nurseAuth");
    const expirationTime = getExpirationTime(token);
    const currentTime = getCurrentTimeInSeconds();

    const timeDifference = expirationTime - currentTime;

    // Check if the token is about to expire in less than 2 minutes (120 seconds)
    if (timeDifference < 120) {
      if (!isRefreshing) {
        isRefreshing = true; // Set refresh in progress

        const newAccessToken = await refreshAuthToken();
        config.headers.Authorization = `Bearer ${newAccessToken}`;

        isRefreshing = false; // Reset refresh flag
        return config; // Return the updated config
      } else {
        // If refresh is in progress, return a promise to handle this request later
        return new Promise((resolve) => {
          pendingRequests.push((newAccessToken) => {
            config.headers.Authorization = `Bearer ${newAccessToken}`;
            resolve(config);
          });
        });
      }
    } else {
      config.headers.Authorization = getAuthToken();
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default nurseAPIClient; // Export the common axios instance
