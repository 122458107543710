import React, { useState } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { isMobile } from "react-device-detect";
import Modal from "react-modal";
import {
  cookieAlertModal,
  smallModalStylesMobile,
} from "../../utils/modalStyles/ModalMainStyle";

const CookiesContainer = () => {
  const [cookieStatus, setCookiesStatus] = useState(null);
  const [modalOpen, setIsModalOpen] = useState(false);

  const handleOnAccept = () => {
    setCookiesStatus(true);
  };
  function closeModal() {
    setIsModalOpen(false);
  }
  const handleLearnMore = () => {
    window.open("https://www.merago.com/privacy-policy");
  };
  const handleDenyClick = () => {
    setIsModalOpen(true);
  };
  let cookieComponent = (
    <>
      <div style={{ fontSize: " 0.9em" }}>
        We use{" "}
        <span
          style={{ color: "#66c0ff", cursor: "pointer" }}
          onClick={handleDenyClick}
        >
          cookies
        </span>{" "}
        to enhance your browsing experience and analyze our traffic. You can
        consent by the clicking "Accept all cookies" button or by closing this
        notice.
      </div>
    </>
  );

  let cookieBtns = (
    <div className="btm__btns">
      <button onClick={handleLearnMore} className="learnMore">
        Learn More
      </button>
      {/* <button className="learnMore deny__button" onClick={handleDenyClick}>
        Deny All
      </button> */}
    </div>
  );
  if (cookieStatus === false) {
    cookieComponent = <div>abcd</div>;
  }

  const consent = Cookies.get("cookieConsent");
  const visible =
    cookieStatus === true || consent === "true" ? "hidden" : "show";
  return (
    <>
      <div className="cookies_Popup">
        <CookieConsent
          overlay
          containerClasses="cookies"
          buttonClasses="btn_accept"
          location="bottom"
          buttonText="Accept All"
          hideOnAccept={false}
          visible={visible}
          onAccept={handleOnAccept}
          cookieName="cookieConsent"
          cookieSecurity={true}
          sameSite="strict"
        >
          {cookieComponent}
          {cookieBtns}
        </CookieConsent>
      </div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        style={!isMobile ? cookieAlertModal : smallModalStylesMobile}
        ariaHideApp={false}
      >
        <div className="html__title__wrapper">
          <div className="html__title flex__modalTitle">
            <h4>Cookie Alert</h4>
          </div>
        </div>
        <div className="modal__body cookie__popup__body">
          <h4>
            These cookies are necessary for the website to function and cannot
            be switched off in our systems. These are used for storing and
            functioning of the following features:
          </h4>
          <br />
          <ul>
            <li>Access Token for a session</li>
            <li>Remember Username</li>
            <li>Video Consultation session information</li>
            <li>Clinic information</li>
          </ul>
          <button onClick={closeModal}>OK</button>
        </div>
      </Modal>
    </>
  );
};

export default CookiesContainer;
