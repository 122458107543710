import React from "react";
import { Link, useLocation } from "react-router-dom";
import navigationItems from "../../utils/constants/NavigationItems";

const ProviderLoginMenuItems = () => {
  const user = localStorage.getItem("userID");
  const location = useLocation();
  return (
    <div>
      {!user && (
        <div className="navbar__login__screen">
          <div className="navbar__logo">
            <img
              src="https://static.meragoapp.com/images/meragoHealth.png"
              alt="logo"
              width="185px"
              height="55px"
            />
          </div>
          {!location?.pathname?.includes("guest") &&
            !location?.pathname?.includes("agent") &&
            !location?.pathname?.includes("nurse") && (
              <div className="navbar__single">
                {navigationItems.loginMenu.map((items) => (
                  <Link
                    key={items.text}
                    to={items.to}
                    className={
                      location.pathname.includes(items.to)
                        ? "navbar_active"
                        : ""
                    }
                  >
                    {items.name}
                  </Link>
                ))}
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default ProviderLoginMenuItems;
