import { useQuery, useQueryClient } from "react-query";
import * as logout from "../services/logoutAPI";

//* Logout hook
export const useLogout = () => {
  const queryClient = useQueryClient();
  return useQuery(["Logout"], () => logout.getLogout(), {
    enabled: false,
    retry: false,
    onSuccess: () => {
      queryClient.clear();
    },
  });
};
