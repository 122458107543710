import { useLocation } from "react-router";
import AgentMenu from "./agentMenu/AgentMenu";

import "./Headers.scss";
import ProvidersMenu from "./ProvidersMenu";
import NurseMenu from "./nurseMenu/NurseMenu";

const MenuLists = () => {
  const location = useLocation();
  return (
    <>
      {location?.pathname.includes("agent") ? (
        <AgentMenu />
      ) : location?.pathname.includes("nurse") ? (
        <NurseMenu />
      ) : (
        <ProvidersMenu />
      )}
    </>
  );
};

export default MenuLists;
